.customeSign {
    .signatureClass {
        border: 1px solid black;
        width: 100%;
        height: 120px;
        margin-top: 9px;
    }
    .existSignature {
        border: 1px solid black;
        width: 100%;
        height: 120px;
        margin-top: 14px;
    }
}
