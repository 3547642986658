.mainDashboard-container {
    .selectclass {
        // background-color: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        padding: 0 1em 0 0;
        margin: 10;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
    }
    .dashHeading {
        text-align: left !important;
        // font-weight: 650;
        margin-left: 20px !important;
        letter-spacing: '1px';

        // font-weight: 500;
        font-weight: 600 !important;
        line-height: 1.5;
        font-family: Inter, sans-serif;
        // display: block;
    }

    .pieChartSize {
        width: 100%;
        height: 140px;
    }
    .barChartBar {
        /* padding: 0px; */
        height: 30px !important;
        overflow: auto !important;
    }

    & .ScrollArrow-Container {
        padding-top: 25px;
        height: 100%;
    }
    .btn-container {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        & .MuiButton-label {
            white-space: nowrap;
        }
    }
    .btn-container::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}
