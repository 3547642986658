.workflow-buttons {
    padding-bottom: 2px;
    & .MuiButton-root {
        min-width: auto !important;
    }
    & > button + button {
        margin-left: 10px;
    }
    // @media (max-width: 767px) {
    //     & > button + button {
    //         margin-left: 3px;
    //     }
    //     .MuiButton-label {
    //         font-size: 0.6rem !important;
    //     }
    // }
}

.primary-red-color {
    color: #f50057;
}

.workflow-job-details {
    padding: 5px;
    padding-top: 0px;

    & > .job-basic-details-container {
        width: 100%;
        min-height: 25px;
        border: 1px solid lightgray;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > .job-basic-details {
            display: flex;
            .cash-message {
                display: block;
                font-weight: bold;
                color: red;
                padding-left: 15px;
                padding-bottom: 5px;
            }

            & > div {
                & > div {
                    padding: 3px 9px;
                    display: inline-flex;
                    font-weight: 500;

                    & > span {
                        font-weight: 400;
                        font-style: italic;
                    }
                }
            }

            & > div + div {
                border-left: 1px solid lightgray;
            }
        }
    }

    .grid {
        flex-wrap: nowrap;

        .grid-left-section {
            width: 100%;
            flex: 0 0 550px;
            @media (min-width: 10px) and (max-width: 1260px) {
                flex: 0 0 100%;
            }

            .steps-container {
                text-align: left;
                border-radius: 5px;
                overflow: auto;
                border: 1px solid lightgray;
                // height: calc(100vh - 256px);

                .one-field-area {
                    padding: 0px 10px 10px 10px;

                    & + .one-field-area {
                        border-top: 1px dashed grey;
                    }

                    &:nth-child(2n + 0) {
                        background: #f7f7f7;
                    }

                    > .step-row {
                        display: flex;
                        align-items: center;
                        height: 2.3em;

                        > .step-label {
                            flex-grow: 1;
                            // max-width: 58.333333%;
                            // flex-basis: 58.333333%;

                            .required-step {
                                font-size: 1em;
                                height: 10px;
                                width: 10px;
                                padding-bottom: 3px;
                                overflow: hidden;
                                display: inline-block;
                                margin-left: 5px;
                                color: red;
                            }
                        }

                        .doc-action-buttons {
                            flex: 0 0 125px;

                            .MuiIconButton-root {
                                padding: 5px;
                            }

                            .upload-btn-input-file {
                                display: none;
                            }

                            .upload-progress {
                                position: absolute;
                            }
                        }
                    }

                    > .step-controls {
                        // max-width: 41.666667%;
                        // flex-basis: 41.666667%;

                        .step-text-box {
                            width: 178px;
                            margin: -1px 4px 0px 0px;
                            input {
                                padding: 8px 10px;
                            }
                        }

                        button.web-form-action-button {
                            width: 178px;
                            white-space: nowrap;
                            text-transform: unset;
                        }

                        > .app-select-btn-grp {
                            button {
                                padding: 3px 8px;
                                text-transform: unset;

                                &.Mui-selected {
                                    color: white;
                                    background-color: rgb(83, 175, 80);
                                }
                            }
                        }

                        > .app-select {
                            width: 150px;

                            > .Mui-disabled {
                                background-color: #dedede;
                            }
                        }
                    }
                }
            }

            .actionButton-Container {
                display: flex;
                align-items: flex-end;
                .ScrollArrow-Container {
                    padding-top: 10px;
                    height: 100%;
                }
                .action-buttons {
                    margin-top: 5px;
                    text-align: left;
                    display: flex;
                    overflow: auto;
                    &::-webkit-scrollbar {
                        width: 1px;
                        height: 1px;
                    }
                    button + button {
                        margin-left: 10px;
                    }

                    .has-records {
                        background-color: #4caf50;
                        color: white;
                    }
                }
            }
        }

        .grid-right-section {
            @media (min-width: 1261px) {
                flex-grow: 1;
            }
            @media (max-width: 1260px) {
                flex: 1;
            }
            position: relative;

            .doc-page-move {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 99;

                & > span {
                    background: #f50057;
                    opacity: 0.3;
                    color: white;
                    height: 24px;
                    width: 24px;

                    &:hover {
                        opacity: 1;
                    }
                }

                &.go-prev {
                    left: 5px;
                }
                &.go-next {
                    right: 5px;
                }
            }

            .document-close-action {
                position: absolute;
                right: 5px;
                display: grid;
                z-index: 99;

                & > span {
                    background: #f50057;
                    opacity: 0.5;
                    color: white;
                    height: 24px;
                    width: 24px;

                    & + span {
                        margin-top: 2px;
                    }

                    &:hover {
                        //background: #f50057;
                        opacity: 1;
                    }
                }

                .stamp-start {
                    animation: blinker 1s linear infinite;
                }

                @keyframes blinker {
                    50% {
                        transform: scale(0.8);
                    }
                }

                .marking-stuff {
                    border: 1px solid lightgray;
                    border-radius: 5px;
                    background-color: white;
                    padding: 5px;

                    .marking-toolbar {
                    }

                    .marking-action {
                        margin-top: 5px;
                    }
                }
            }
            .document-action {
                position: absolute;
                right: 5px;
                bottom: 10px;
                display: grid;
                z-index: 99;

                .red-on-white {
                    .MuiBadge-badge {
                        outline: 1px solid #f50057;
                        color: #f50057;
                        background-color: white;
                    }
                }

                & > span {
                    background: #f50057;
                    opacity: 0.5;
                    color: white;
                    height: 24px;
                    width: 24px;

                    & + span {
                        margin-top: 2px;
                    }

                    &:hover {
                        //background: #f50057;
                        opacity: 1;
                    }
                }

                .stamp-start {
                    animation: blinker 1s linear infinite;
                }

                @keyframes blinker {
                    50% {
                        transform: scale(0.8);
                    }
                }

                .marking-stuff {
                    border: 1px solid lightgray;
                    border-radius: 5px;
                    background-color: white;
                    padding: 5px;

                    .marking-toolbar {
                    }

                    .marking-action {
                        margin-top: 5px;
                    }
                }
            }

            .document-viewer {
                // height: calc(100vh - 210px);
                border: 1px solid lightgray;
                margin-left: 10px;
                border-radius: 5px;
                position: relative;

                .refStampContainer {
                    position: relative;

                    .canvas-wrapper {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: calc(100% - 10px);
                        height: calc(100% - 10px);

                        canvas.main-canvas {
                            width: 100%;
                            height: 100%;
                            background: rgba(171, 171, 171, 0.18);
                        }
                    }
                }

                &.multi-image-from-pdf {
                    background: gray;
                }

                &.marking-start {
                    cursor: copy;
                }

                img {
                    -webkit-user-drag: none;
                    -webkit-user-drag: none;

                    &.deg-0 {
                        width: 100%;
                    }

                    &.deg-90 {
                        height: 100%;
                        transform: rotate(-90deg);
                    }

                    &.deg-180 {
                        width: 100%;
                        transform: rotate(-180deg);
                    }

                    &.deg-270 {
                        height: 100%;
                        transform: rotate(-270deg);
                    }

                    &.sample-doc {
                        margin-top: 100px;
                        width: 250px;
                    }
                }

                embed {
                    width: 100%;
                    height: 100%;
                }

                .stamp {
                    position: absolute;
                    //transition: all 0.2s;

                    img {
                        height: 100%;
                        width: 100%;
                        display: block;
                        -webkit-user-drag: none;
                        -webkit-user-drag: none;
                    }

                    > .remove-stamp {
                        right: -12px;
                        top: -12px;
                        height: 25px;
                        position: absolute;
                        opacity: 0;
                        color: orangered;
                        //transition: all 0.2s;
                        background-color: white;
                        cursor: pointer;
                        border-radius: 15px;
                        border: 1px solid orangered;
                        z-index: 1;

                        &.scale-deci7 {
                            transform: scale(0.7);
                        }
                    }

                    &.in-edit:hover {
                        outline: 1px solid orangered;

                        .remove-stamp {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.set-background-red {
    background-color: #c71204f2;
    color: #f7f7f7;
}

.dialog-custom {
    .title-header-actions {
        position: absolute;
        top: 15px;
        right: 15px;

        .close-icon {
            margin-left: 20px;
            cursor: pointer;
            position: relative;
            top: 5px;
            width: 18px;
            display: inline-block;
            height: 18px;
            font-size: 30px;
            line-height: 20px;
            opacity: 0.8;
        }

        .close-icon:hover {
            opacity: 1;
        }
    }
}

.job-notes-modal {
    // .MuiPaper-root {
    //     width: 600px;
    // }
    .MuiDialogTitle-root {
        padding: 16px 18px 5px 22px;
    }

    .notes-container {
        height: 300px;
        border: 1px solid lightgray;
        padding: 5px;
        border-radius: 4px;
        overflow-y: auto;
        font-style: italic;
        margin-bottom: 20px;

        .single-note {
            padding: 5px;

            .msg {
                font-size: 14px;
            }

            .footer {
                font-size: 12px;
                color: gray;
                text-align: right;
            }
        }

        .single-note + .single-note {
            border-top: 1px solid lightgray;
        }
    }
}

.workflow-movement-modal {
    .MuiPaper-root {
        width: 900px;
    }

    .movement-history {
        border: 1px solid lightgray;
        border-radius: 4px;
        font-size: 13px;
        font-style: italic;

        .movement-history-header,
        .movement-history-body {
            width: 100%;
            border-collapse: collapse;

            tr {
                height: 30px;

                &.header {
                    background-color: lightgray;
                }

                th,
                td {
                    padding: 5px;
                    text-align: left;

                    &.name-type {
                        width: 175px;
                    }

                    &.time-type {
                        width: 150px;
                    }
                }
            }
        }

        .body-table-container {
            max-height: 500px;
            min-height: 100px;
        }
    }
}

.step-webform-modal {
    .MuiPaper-root {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }

    .bg-primary {
        box-shadow: 0px 0 13px 4px lightgrey;
        text-align: center;
        color: gray;
        border: 1px solid lightgrey;
        background: #d3d3d36b;
        height: 25px;
    }

    .loading-label {
        margin-left: 50%;
        margin-top: 40%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}

.return-reason-modal {
    .MuiPaper-root {
        width: 450px;
    }

    .return-reason-select {
        width: 100%;
        margin-top: 5px;

        select {
            padding: 10px;
        }
    }
}

.ocrDocument-history-modal {
    width: 560px;

    .MuiBackdrop-root {
        width: 560px;
    }

    .MuiPaper-root {
        width: 700px;
        margin: 10px;
    }

    .ocrDoc-history {
        border: 1px solid lightgray;
        border-radius: 4px;
        font-size: 13px;
        font-style: italic;

        .ocrDoc-history-header,
        .ocrDoc-history-body {
            width: 100%;
            font-size: 90%;
            border-collapse: collapse;

            tr {
                height: 30px;

                .icon-button-group {
                    > span {
                        padding: 5px;
                    }

                    .rotate-y-180 {
                        transform: rotateY(180deg);
                    }
                }

                &.header {
                    background-color: lightgray;
                }

                &.row {
                    background-color: #d3d3d387;
                }

                &.is-current-doc {
                    background-color: #23ef2350;
                }

                th,
                td {
                    padding: 5px;
                    text-align: left;

                    &.is-current {
                        width: 60px;

                        span {
                            padding: 0px;
                        }
                    }

                    &.time-type {
                        width: 120px;
                    }

                    &.actions {
                        width: 110px;
                    }
                }
            }
        }

        .body-table-container {
            max-height: 500px;
            min-height: 100px;
        }
    }
}

.job-search-modal {
    .MuiPaper-root {
        width: 600px;
    }

    .job-search {
        border: 1px solid lightgray;
        border-radius: 4px;
        font-size: 13px;
        font-style: italic;

        .job-search-header,
        .job-search-body {
            width: 100%;
            border-collapse: collapse;

            tr {
                height: 30px;

                &.header {
                    background-color: lightgray;
                }

                &.row {
                    background-color: #d3d3d387;
                }

                &.is-selected-job {
                    background-color: #23ef2350;
                }

                th,
                td {
                    padding: 5px;
                    text-align: left;

                    &.is-selected {
                        width: 30px;

                        span {
                            padding: 0px;
                        }
                    }

                    &.col-wip,
                    &.col-reg {
                        width: 90px;
                    }

                    &.col-due-in {
                        width: 150px;
                    }
                }
            }
        }

        .body-table-container {
            max-height: 500px;
            min-height: 100px;
        }
    }
}

.all-documents-modal {
    width: 560px;

    .MuiBackdrop-root {
        width: 560px;
    }

    .MuiPaper-root {
        width: 700px;
        margin: 10px;
    }

    .all-documents {
        border: 1px solid lightgray;
        border-radius: 4px;
        font-size: 13px;
        font-style: italic;

        .all-documents-header,
        .all-documents-body {
            width: 100%;
            border-collapse: collapse;
            font-size: 90%;

            tr {
                height: 30px;

                &.header {
                    background-color: lightgray;
                }

                &.row {
                    background-color: #d3d3d387;
                }

                th,
                td {
                    padding: 0px 5px;
                    text-align: left;

                    &.time-type {
                        width: 120px;
                    }

                    &.created-by {
                        width: 140px;
                    }

                    &.actions {
                        width: 90px;

                        .icon-button-group {
                            > span {
                                padding: 5px;
                            }
                        }
                    }
                }
            }
        }

        .body-table-container {
            max-height: 500px;
            min-height: 100px;
        }
    }
}

.attachment-name {
    display: flex;
    & > span {
        font-size: 13px;
        font-style: italic;
        //color: gray;
    }
}

.sending-loader {
    position: absolute;
}

.stamp-sample {
    width: 100%;
    display: block;
    transform: scale(0.85);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1);
    }
}

.back-action {
    & > span {
        background: #f50057;
        opacity: 0.5;
        color: white;
        height: 22px;
        width: 22px;

        margin-right: 6px;
        & + span {
        }

        &:hover {
            background: #f50057;
            opacity: 1;
        }
    }
}
.qc_control {
    .multilineText {
        .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
            padding-bottom: 16.5px;
        }
    }
    .multilineActionText {
        .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
            padding-bottom: 3.5px;
            padding-top: 6px;
        }
    }
}
