.weekly-flash-report-container {
    padding: 0px 10px;
    .MuiBreadcrumbs-ol {
        padding: 10px 0px 0px 0px !important;
        margin: 0px !important;
    }
    .component-container {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
        border-collapse: collapse;
    }
    td,
    th {
        padding: 0.75rem;
        vertical-align: top;
        border: 2px solid #e9ecef;
    }
    .table-responsive {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-bordered {
        border: 1px solid #dee2e6;
    }

    .table td,
    .table th {
        font-size: 14px;
        padding: 0.6rem;
    }

    .header-container {
        padding: 7px;
        text-align: left;
        font-size: 16px !important;
        color: white;
        display: flex;
    }
    .icon-container {
        padding-top: 2px;
        margin-right: 5px;
        .MuiSvgIcon-root {
            font-size: 16px !important;
        }
    }
    .table-container {
        padding: 10px;
        text-align: left;
    }

    .g-brd-blue {
        border-color: #3398dc !important;
    }
    /* Color Aqua */
    .g-bg-aqua {
        background-color: #29d6e6 !important;
    }
    .g-bg-blue {
        background-color: #3398dc !important;
    }
    .g-bg-black {
        background-color: #000 !important;
    }
    .g-bg-green {
        background-color: #72c02c !important;
    }
    .g-bg-pink {
        background-color: #e81c62 !important;
    }
    .g-bg-bluegray {
        background-color: #585f69 !important;
    }
    .g-bg-skype {
        background-color: #00aff0;
    }
    .g-bg-cyan {
        background-color: #00bed6 !important;
    }
    .g-bg-gray-dark-v5 {
        background-color: #999 !important;
    }
}
